import React, { Component, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SwiftType from "../components/swift-type";
import ModuleSubmenuNav from "../modules/module-submenu-nav";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";
import HubspotForm from "../components/hubsport-form";
import { Helmet } from "react-helmet";
import { slugify } from "../helpers/helpers";
import { includeTopLevelClassName } from "../helpers/helpers";
import HeaderWithCarousel from "../modules/headers/header-carousel";

const MainPage = ({ data, location, pageContext }) => {
  const { contentfulTemplateMainPage: page } = data;
  const metaTitle = page?.seoPageTitle || page?.pageTitle;
  const metaDesc = page?.seoPageDescription?.seoPageDescription || null;
  const metaImage = page?.seoShareImage?.file?.url;
  const addForm = data.contentfulTemplateMainPage.addForm;
  const color = addForm?.backgroundColor ? slugify(addForm?.backgroundColor) : "white";
  const backgroundImageUrl = addForm?.backgroundImage ? addForm?.backgroundImage : null;
  const formStyle = {
    backgroundImage: `url(${backgroundImageUrl?.file?.url})`,
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat",
  };
  const isNavbarOpaque = data.contentfulTemplateMainPage.opaqueNavigation;
  const pageSlug = data.contentfulTemplateMainPage.slug;
  const useSimple = includeTopLevelClassName(pageSlug);
  const extraProps = {simple: useSimple};

  useEffect(() => {
    const obj = { url :location.href }
    if(page.slug === "/") {
      obj.contentType = "homepage"
      obj.pageType = "homepage"
      obj.pageTitle = metaTitle.split(" | ")[0]
      obj.sbu= "ULX"
      obj.form= "no"
    } else if(page.slug === "careers") {
      obj.contentType = "careers"
      obj.pageType = "section"
      obj.pageTitle = "Careers at UnitedLex"
      obj.sbu= "ULX"
      obj.contentPublicationDate = "2022-03-18T08:06:38Z"
      obj.timestamp = "2022-03-18T15:23:44"
      obj.form= "no"
    } else if(page.slug === "about-unitedlex") {
      obj.contentType = "about"
      obj.pageType = "section"
      obj.pageTitle = "About UnitedLex"
      obj.sbu= "ULX"
      obj.contentPublicationDate = "2022-03-18T08:06:38Z"
      obj.timestamp = "2022-03-18T15:23:44"
      obj.form= "no"
    } else if(page.slug==="news-and-events") {
      obj.contentType = "news"
      obj.pageType = "section"
      obj.pageTitle = "News and Events"
      obj.sbu= "ULX"
    }
    window?.dataLayer?.push(obj);
  }, [page.slug]);


  /* useSimple is solely to determine whether simple versions
      of the Related Content insight or teardown cards should be used.
      For now, they should just be used if the slug is 'vantage' or 'products'
      as defined in an array within the helper function includeTopLevelClassName */

  const header = data.contentfulTemplateMainPage.header;
  let headerMarkup = null;

  if (header) {
    headerMarkup = moduleConductor(header, 0, {
      slug: pageSlug,
    });
  }

  const recentInsights = data.recentInsights ? data.recentInsights.nodes : null;

  const modules = data.contentfulTemplateMainPage.modules;
  let modulesMarkup = null;
  let submenuLinks = [];

  // if (modules) {
  //   modulesMarkup = modules.map((module, i) => {
  //     if (module.submenuTitle) {
  //       submenuLinks.push(module.submenuTitle);
  //     }
  //     return moduleConductor(module, i, recentInsights);
  //   });
  // }

  if (modules) {
    modulesMarkup = modules.map((module, i) => {
      if (module.submenuTitle) {
        submenuLinks.push(module.submenuTitle);
      }
      // Insert Services carousel before CTA Banner, if Services carousel exists
      if (
        module.__typename === "ContentfulModuleCtaBanner" &&
        pageContext.subPages &&
        data.contentfulTemplateMainPage.includeSubPagesCarousel
      ) {
        // data.contentfulTemplateMainPage.
        // includeSubPagesCarousel
        // subPagesCarouselHeader
        submenuLinks.push("Learn More");
        return (
          <React.Fragment>
            <div className="template-solution__related-services">
              <ModuleCarousel
                // numDesktopSlides={3}
                cards={pageContext.subPages}
                moduleHeadline={
                  data.contentfulTemplateMainPage.subPagesCarouselHeader
                    ? data.contentfulTemplateMainPage.subPagesCarouselHeader
                    : null
                }
                submenuTitle={"Learn More"}
              />
            </div>

            {moduleConductor(module, i)}
          </React.Fragment>
        );
      }

      return moduleConductor(module, i, extraProps);
    });
  }
  const addScript = (url) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return script;
  };
  const removeScript = (script) => {
    document.body.removeChild(script);
  };
  useEffect(() => {
    // Loader();
    // if (!document.getElementById("loader_wrap").classList.contains("loaded")) {
    //   document.getElementById("loader_wrap").classList.remove("d-none");
    // }
    // setTimeout(() => {
    //   document.getElementById("loader").classList.add("active");
    // }, 10);
    // setTimeout(function () {
    //   document.getElementById("loader_wrap").classList.add("loaded");
    //   document.getElementById("loader_wrap").classList.add("d-none");
    // }, 1000);
    //return () => {
    //  removeScript(scriptJquery);
    //  removeScript(script);
    //}
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.0005;

    for (let x = 0; x < 64; x++) {
      let width = x * 1.5625 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = (x - 32) * (x - 32) * delay + "s";
      div.style.transitionDelay = (x - 32) * (x - 32) * delay + "s";
    }
  };

  // const { contentTypes, solutions, tags } = props;

  const fallbackDesc =
    "UnitedLex combines innovative service models, digitally powered solutions, management consulting, and legal expertise to revolutionize the legal industry. We advise on the largest and most complex legal challenges facing the world’s most important companies.";

  return (
    <Layout isSidebar="contact" isWhiteNav={isNavbarOpaque} additionalClassName={pageSlug} location={location}>
      <SwiftType
        customTitle={metaTitle}
        customDesc={metaDesc || fallbackDesc}
      />
      <SEO
        title={metaTitle || "UnitedLex"}
        description={metaDesc || fallbackDesc}
        url={location.href}
        image={metaImage}
      />
      {headerMarkup}
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.2/jquery.min.js"></script>
      </Helmet>

      {submenuLinks.length > 0 ? (
        <ModuleSubmenuNav
          items={submenuLinks.length > 0 ? submenuLinks : null}
        />
      ) : null}
      {modulesMarkup}
      {addForm && (
        <React.Fragment>
          <div id="connect" className={`hubspot-form__wrapper hubspot-form__wrapper--${color}`} style={formStyle}>
            <HubspotForm
              portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
              formId={addForm.formId}
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => {
                console.log("Form ready: ", form);
              }}
              loading={<div>Loading...</div>}
              invertTextColor={false}
              formHeader={
                addForm.formHeader ? addForm.formHeader : null
              }
              successMessage={
                addForm.successMessage? addForm.successMessage : null
              }
            />
          </div>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default MainPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateMainPage(slug: { eq: $slug }) {
      slug
      doNotIndex
      seoPageTitle
      seoPageDescription {
        seoPageDescription
      }
      seoShareImage {
        ...ContentfulAssetFragment
      }
      header {
        ...ModuleHeaderSimpleFragment
        ...ModuleHeaderWithMediaFragment
        ...ModuleHeaderTwoColumnCardsFragment
        ...ModuleHeaderWithCarouselFragment
      }
      modules {
        ...ModuleExpandingCardsFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleThreeColumnCardsFragment
        ...ModuleTimelineFragment
        ...ModuleFeaturesCardsFragment
        ...ModuleCtaBannerFragment
        ...ModulePullQuotesFragment
        ...ModuleCarouselFragment
        ...ModuleRelatedContentFragment
        ...ModuleLocationsMapContentfulFragment
        ...ModuleInsightBannerFragment
        ...ModuleFeaturedBannerFragment
        ...ModuleSimpleSectionHeadingFragment
      }
      addForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
        backgroundImage {
          ...ContentfulAssetFragment
        }
        backgroundColor
      }
      includeSubPagesCarousel
      subPagesCarouselHeader
      opaqueNavigation
    }
  }
`;
